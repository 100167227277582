import React from "react";
import "./kontakt-page.scss";
import { Helmet } from "react-helmet-async";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import StandardBlock from "../../components/standard-block/standard-block";
import TitleBlock from "../../components/title-block/title-block";
import FullMapSection from "../../components/map-section/map-section";
import { Col, Container, Grid, Row } from "codeebo-react-grid";
import T from "../../../app/components/lang-switch/t";

const KontaktPage = () => {
  return (
    <PageWrapper className="kontakt-page">
      <Helmet>
        <title>{"LOG-TECH"}</title>
      </Helmet>

      <section>
        <Container>
          <MainHeader align="left">{T(["Kontakt", "Contact", "Kontakt"])}</MainHeader>
          <Grid>
            <Row>
              <Col size={[3, 3, 6, 12]}>
                <StandardBlock>
                  <TitleBlock>Log-Tech Konstrukcje</TitleBlock>
                  <p>
                    LOG TECH KONSTRUKCJE <br /> SP Z O O SPÓŁKA KOMANDYTOWA
                  </p>
                  {/* <img className="large-logo mt-2" src="/images/logo-large.jpg" alt="logo" style={{ maxWidth: 140 }} /> */}
                </StandardBlock>
              </Col>
              <Col size={[2, 3, 6, 12]}>
                <StandardBlock>
                  <TitleBlock>{T(["Adres", "Adress", "Adresse"])}</TitleBlock>
                  <p>
                    ul. Strefowa 1, <br />
                    56-500 Syców, <br /> Polska
                  </p>
                </StandardBlock>
                <br />
                <br />
              </Col>
              <Col size={[3, 3, 6, 12]}>
                <StandardBlock>
                  <TitleBlock>{T(["Dane rejestrowe", "Registration details", "Registrierungsdaten"])}</TitleBlock>
                  <p>
                    NIP:9112005792 <br />
                    REGON:021894053 <br />
                    KRS:0000422981
                  </p>
                </StandardBlock>
              </Col>
              <Col size={[4, 3, 6, 12]}>
                <StandardBlock>
                  <TitleBlock>{T(["O malarni", "Our office", "Sekretariat"])}</TitleBlock>
                  <p>
                    Telefon:&nbsp;<a href="tel:625033771">62 503 37 71</a> <br />
                    Email:&nbsp;<a href="mailto:sektretariat@malarnia.log-tech.pl">sektretariat@malarnia.log-tech.pl</a>
                  </p>
                </StandardBlock>
              </Col>
            </Row>
          </Grid>
          <br />
          <br />
          <br />
          <br />
          <FullMapSection />
        </Container>
      </section>
    </PageWrapper>
  );
};

export default KontaktPage;
